<template>
  <div class="d-flex flex-nowrap">
    <v-icon>{{$icon('i.Groupama')}}</v-icon>
    <div class="d-flex flex-column flex-nowrap">
      <div>
        <span class="mr-1">{{$t('t.Number#')}}</span>
        <span class="mr-1">{{number}}</span>
      </div>
      <slot name="doc-type" />
    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
  },
  computed: {
    number () {
      return this.id || this.$t('t.None')
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.GroupamaRequestRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: [String, Number],
    showIcon: {
      type: Boolean,
      default: true
    },
    options: Object
  }
}
</script>
